@import "_partials/variables"
@import "_partials/animations"
@import "_partials/basic"


// Structural

.navbar-wrapper
	display: block
	z-index: 999
	position: relative

.navbar
	position: absolute
	max-width: 1240px
	width: 100%
	padding: 1em 1.2em
	display: flex
	justify-content: space-between
	top: 0
	left: 50%
	-webkit-transform: translateX(-50%)
	transform: translateX(-50%)

	.logo
		box-shadow: 11px 8px 0px -4px #f89d33
		border: 5px solid #222831
		background-color: $background-color

	.link
		padding: 10px 0


.section
	width: 85%
	max-width: 1240px
	margin: 0 auto
	padding: 4em 0 6em

	&-hero
		display: flex
		justify-content: space-between
		padding-bottom: 0
		margin-bottom: 3em

	&-full-screen
		min-height: 100vh

	&:last-child
		padding-bottom: 10em

	&:nth-child(2)
		padding-top: 2em

router-outlet ~ *
	position: absolute
	height: 100%
	width: 100%


.wrapper
	position: relative

.section-header
	max-width: 520px
	margin-top: 0
	font-size: 5em

.content
	margin-top: 3em
// Hero Section 

.hero-heading
	width: 420px
	min-height: 250px
	padding-top: 5vh
	flex-grow: 1
	flex-shrink: 1

	&-solo
		min-height: auto

	h1
		font-size: 5em

.hero-image
	display: flex
	justify-content: center
	flex-grow: 0
	flex-shrink: 999
	margin-left: 4em
	margin-bottom: 4em
	max-width: 600px

	img,
	object
		max-width: 100%


//Contact Form Section

.section-contact-form-content
	display: flex
	flex-wrap: wrap

.contact-form-wrapper
	flex-grow: 999

.contact-form-container
	max-width: 600px

	form
		margin: 4rem 0 2rem

		.btn
			float: right

			&:after
				content: ""
				clear: both
				display: table

.contact-form-image
	max-width: 460px
	margin-left: 4rem

	img
		max-width: 100%



@media screen and (max-width: 768px)
	.navbar
		width: 100%
		max-width: 100%
		padding: 10px 16px

	.section
		width: 100%
		max-width: 100%
		padding-left: 20px
		padding-right: 20px

		.section-header
			max-width: 380px
			font-size: 2.8em

		&-hero
			flex-wrap: wrap

	.hero-heading
		h1
			font-size: 3em

	.hero-image
		margin-left: 0
		max-height: 400px
		max-width: 100%

	.contact-form-image
		img
			margin-left: 0


@media screen and (min-height: 100vw) and (min-height: 768px) and (min-width: 767px)
	.section
		&-full-sreen
			min-height: auto
