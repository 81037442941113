@import "variables"

*,
*::after,
*::before
	box-sizing: border-box

// Typography
html
	font-size: 18px

body
	background-color: $background-color
	color: $text-color
	font-family: $font-main
	font-weight: 400
	line-height: 1.65
	margin: 0

p
	margin-bottom: 1.25em

h1, h2, h3
	margin: 2.7rem 0 0.5em
	font-family: $font-header
	font-weight: 400
	line-height: 1.15

h1
	font-size: 3.052em

h2
	font-size: 2.441em

h3
	font-size: 1.953em

h4, h5
	margin: 0.6em 0 0.8em
	font-family: $font-main

h4
	font-size: 1.1em

h5
	font-size: 1.1em

small, .text_small
	font-size: 0.8em

.lead
	font-size: 1.2em
	line-height: 1.8em

.link
	position: relative
	color: $link-color
	background: transparent
	border: 0
	font-family: inherit
	padding: 2px 0
	text-decoration: none
	cursor: pointer

	&-lg
		padding: 10px 0
		font-size: 1.2em

	&:hover,
	&:focus
		color: $primary-color
		outline: 0
		transition: color .6s

	&::after
		content: ''
		position: absolute
		left: 0
		bottom: 0
		width: 100%
		height: 2px
		background: $primary-color
		opacity: 0
		transform: translateY(5px)
		transition: transform .3s, opacity .3s
		pointer-events: none

	&:hover,
	&:focus
		&::after
			opacity: 1
			transform: translateY(0)

.link-group

	.link
		display: inline-block
		margin-left: 1em

		&:first-child
			margin-left: 0

.unstyled-list
	list-style: none


.inline-list li
	display: inline
	padding: 0 0.4em


.btn
	border: 0
	background: $primary-color
	color: #fff
	cursor: pointer
	border-radius: 63px 30px 60px 40px
	padding: 1em 2em
	font-size: 1rem
	font-family: $font-main
	transition: box-shadow 300ms ease-out, color 300ms ease-out

	&:disabled, &:disabled:hover
		cursor: default
		background: #d7e6e4
		color: $text-color

	&:hover:not(:disabled),
	&:focus:not(:disabled)
		outline: 0
		box-shadow: 0.9em 0.9em 0 -0.3em $secondary-color


.badge
	display: inline-block
	background: $primary-color
	padding: 3px 10px
	margin-right: 10px
	border-radius: 24px 11px 23px 13px
	color: $background-color
	cursor: pointer

.separator
	display: inline-block
	width: 2px
	height: 1em
	margin: 0 .7em
	background: $link-color

.form-field
	position: relative
	margin-bottom: 2rem

	input, textarea
		width: calc(100% - 14px)
		font-size: 1rem
		font-family: $font-main
		font-weight: 300
		line-height: 2.4
		margin-left: 14px
		padding: 0.4em 1.6em
		border: 0
		background-color: #d7e6e4
		transition: background-color 300ms ease-in-out

		&:focus, &:active
			outline: 0
			background-color: #a8e1e4


		&.ng-touched.ng-invalid
			outline: 0
			background-color: rgb(255, 195, 195)


	label
		display: block
		position: absolute
		top: -31px
		width: 100%
		margin: 0
		transition: all 400ms ease-out
		font-size: 1.6rem
		font-family: $font-header
		font-weight: 400
		color: #222831

	.field-errors
		font-family: $font-main
		font-size: 1rem
		padding-left: 0.8em


	&--active, &--filled
		label
			font-size: 0.8rem
			transform: translateY(-1rem)
			color: $primary-color

@media screen and (max-width: 768px)
	html
		font-size: 16px

	h1
		font-size: 2.5em

	h2
		font-size: 2.2em

	h3
		font-size: 1.7em
